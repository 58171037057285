import * as React from 'react';
import { Theme, ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { bg_color, button_color, text_color } from '../themes/themeTelegram';
import logo from "../assets/mango_3.png"
import { Button, outlinedInputClasses, TextField, Typography } from '@mui/material';
import { Chip } from "@mui/material"
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import styles from './styles.module.css'

import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import { BO } from 'country-flag-icons/react/3x2';
import { getUrl, current_user } from '../core/UrlService';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import light_precomp from "../assets/Duck.json";



const fn = (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) => (index: number) =>
    active && index === originalIndex
        ? {
            y: curIndex * 50 + y,
            scale: 1.1,
            zIndex: 1,
            shadow: 15,
            immediate: (key: string) => key === 'y' || key === 'zIndex',
        }
        : {
            y: order.indexOf(index) * 50,
            scale: 1,
            zIndex: 0,
            shadow: 1,
            immediate: false,
        }


export default function RoutersOrder(
    props: any
) {
    const items = ["Оплатить"]
    const { sxBackground, children } = props;
    const order = React.useRef(items.map((_, index) => index)) // Store indicies as a local ref, this represents the item order
    const [springs, api] = useSprings(items.length, fn(order.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.
    const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
        const curIndex = order.current.indexOf(originalIndex)
        const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, items.length - 1)
        const newOrder = swap(order.current, curIndex, curRow)
        api.start(fn(newOrder, active, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
        if (!active) order.current = newOrder
    })

    const [address, setAddress] = React.useState("");
    const [comment, setComment] = React.useState("");
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.tg.BackButton.show()
        props.tg.MainButton.hide()
        props.tg.BackButton.onClick(() => {
            navigate("/whypn_routers")
        })
        /*       if (props.tg.initDataUnsafe.user.language_code == "en"
              ) {
                  onStartClick()
              } */


    }, [])

    var onPayClick = () => {
        setIsLoading(true)
        fetch(getUrl("/api/router_order", props.tg) + "address=" + address + "&comment=" + comment)
            //fetch("http://localhost:5010" + "/api/transactionFree?userId=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ))
            /*  .then(res => res.json()) */
            .then(
                (result) => {
                    debugger
                    props.tg.openLink("https://pay.whyrl.ru/pay?user_id=" + (props.tg?.initDataUnsafe?.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + 17990 + "&key=5router_preorder&status=1")
                    if (props.tg != null)
                        props.tg.close()

                    setIsLoading(false)

                },
                (error) => {
                    setIsLoading(false)
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }


    const [available, setAvailable] = React.useState([]);
    const [name, setName] = React.useState("");

    var handleAddressChange = (e) => {
        setAddress(e.target.value)
    }

    var handleCommentChange = (e) => {
        setComment(e.target.value)
    }


    const customTheme = (outerTheme: Theme) =>
        createTheme({
            /*     palette: {
                    mode: outerTheme.palette.mode,
                }, */
            components: {
                MuiTextField: {
                    styleOverrides: {
                        /*   root: {
                              '--TextField-brandBorderColor': '#E0E3E7',
                              '--TextField-brandBorderHoverColor': '#B2BAC2',
                              '--TextField-brandBorderFocusedColor': '#6F7E8C',
                              '& label.Mui-focused': {
                                  color: props.tg.themeParams.text_color ?? '#1E88E5',
                              },
                          }, */
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
/*                             borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd',
 */                            border: "1px solid",
                            color: props.tg.themeParams.text_color ?? '#1E88E5',
                            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'

                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                /*     borderColor: '#bdbdbd' */
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                        },
                    },
                },

            },
        });

    // var onPayClick = () => {
    // var a = location.state.amount
    // props.tg.openLink("https://pay.whyrl.ru/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + 14999 + "&key=5&status=1")
    //  window.open("https://pay.whyrl.ru/pay?user_id=" + (props?.tg?.initDataUnsafe?.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + 14999 + "&key=5&status=1", "_blank")
    /*    props.tg.close() */
    //}

    const outerTheme = useTheme()


    return (
        <Box sx={{ mt: 2 }} style={{ /*  background: '#ffdf07', position: 'absolute', left: 0, right: 0, top: 0, height: '100vh'*/ }}>
            {
                isLoading
                    ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
                        <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />
                        <Typography /* className="pre_" */ sx={{ mt: 1 }} color={text_color} >I'm thinking... </Typography>

                        {/*             <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: props.tg.themeParams.text_color ?? '#1E88E5' }} />
*/}        </Box>
                    : <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}
                    >
                        <Typography variant='h4' textAlign={"center"} color={text_color}>Адрес и оплата</Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box color={bg_color} style={{
                               /*  backgroundColor: 'white', */ borderRadius: '12px', display: 'flex',
                                justifyContent: 'space-between', alignItems: 'center'
                            }} p={2} >
                                <form>



                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <TextField sx={{ input: { color: text_color } }} InputProps={{ sx: { borderRadius: '12px' } }}
                                            defaultValue={name} style={{
                                                borderRadius: '12px', color: button_color

                                            }} placeholder="Адрес"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={address}
                                            onChange={handleAddressChange} focused />
                                    </ThemeProvider>

                                    <ThemeProvider theme={customTheme(outerTheme)}>
                                        <TextField

                                            sx={{ input: { color: text_color } }}
                                            InputProps={{ sx: { borderRadius: '12px' } }}
                                            defaultValue={name} style={{ borderRadius: '12px', color: button_color }}

                                            placeholder="Комментарий"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={comment}
                                            onChange={handleCommentChange}
                                            focused />
                                    </ThemeProvider>


                                    <Button onClick={onPayClick} variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, borderRadius: 0, p: 1, width: '100%' }}
                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: '#ffdf07', color: '#313131' }} >
                                        Перейти к оплате
                                    </Button >
                                    {/*      <div className={styles.content} style={{ height: items.length * 50 }} onClick={onPayClick}>
                            {springs.map(({ zIndex, shadow, y, scale }, i) => (
                                <animated.div
                                    {...bind("Заказать")}
                                    key={i}
                                    style={{
                                        zIndex,
                                        boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                                        y,
                                        scale,

                                    }}
                                    children={items[i]}
                                />
                            ))}
                        </div> */}

                                    {/*      <Button
                            style={{width:'100%'}}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Заказать
                        </Button> */}
                                </form>
                            </Box>
                        </Box>
                    </Box>


            }


        </Box>

    );
}

